import {getAjaxRequest,postAjaxRequest} from '@/utils/request'
import {yqfApiurl} from '@/utils/webconfig'

export function GetVisaNavs(parm, callback) {
	console.log('GetVisaNavs');
	postAjaxRequest('/VisaApi/GetVisaNavs', parm, function(result) {
		callback(result);
	})	
}
//热门签证
export function GetHotVisaList(parm, callback) {
	console.log('GetHotVisaList');
	postAjaxRequest('/VisaApi/GetHotVisaList', parm, function(result) {
		callback(result);
	})	
}
//获取签证列表
export function GetFrontVisaList(parm, callback) {
	console.log('GetFrontVisaList');
	postAjaxRequest('/VisaApi/GetFrontVisaList', parm, function(result) {
		callback(result);
	})	
}
//获取签证详情
export function GetVisaDetailInfo(parm, callback) {
	console.log('GetVisaDetailInfo');
	postAjaxRequest('/VisaApi/GetVisaDetailInfo', parm, function(result) {
		callback(result);
	})	
}